@use '~theme/colors' as *;
@use '~theme/typography' as *;

.time-selector-row {
	display: flex;
	align-items: center;
	.time-selector-row-content {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.loading.loading-centered {
			width: auto;
			svg {
				width: 32px;
				height: 32px;
			}
		}
		.calendar-interval-container {
			@include font-regular-text-md;
			line-height: 16px;
		}

		.range-text {
			float: right;
			text-align: right;
			display: flex;
			align-items: center;
			.date-text {
				display: inline-block;
				padding: 0px 5px;
			}
		}
	}
}
