@use '~theme/colors' as *;
@use '~theme/typography' as *;

.general-info-container {
    @include font-bold-text-md;
    display: flex;
    gap: 16px;
    padding: 12px 12px 12px 16px ;
    border-radius: 0px 0px 4px 4px;
    border-top: 4px solid $blue-ribbon;
    box-shadow: 0px 4px 8px 0px #00000014,
                0px 0.5px 2px 0px #00000014,
                0px 0px 1px 0px #00000014;

    .info-text {
        flex: 1 1 auto;
    }
                
    .close-icon {
         >span {
            cursor: pointer;
         }
    }
}