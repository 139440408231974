@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

.text-area-container {
	margin-left: 5px;
	.text-area-component {
		@include font-regular-text-md;
		@include create-border($radius: 0px);
		background-color: $white;
		width: 100%;
		min-height: 80px;
		margin-top: 5px;
		font-weight: 300;

		&:hover {
			background: $alto-transparent;
		}

		&:focus-visible {
			outline: 1px solid $dodger-blue;
		}

		&:focus {
			&:hover {
				background: none;
			}
		}
	}

	.text-area-description {
		color: $dove-gray;
	}
}
