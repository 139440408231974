@use '~theme/typography' as *;
@use "~theme/colors" as *;

.expandable-component {
	.header {
		@include font-regular-text-md;
		min-height: 30px;
		display: flex;
		cursor: pointer;
		align-items: center;
		line-height: 16px;
		border: none;
		width: 100%;
		justify-content: space-between;

		&.right-arrow {
			display: flex;
			flex-direction: row-reverse;
		}

		.title-content {
			line-height: 30px;
		}

		.open-close-container {
			margin-left: 10px;
			line-height: 30px;
			display: flex;
			align-items: center;

			&.open-icon {
				animation: openAnimation 0.1s ease-in-out;
			}

			&.close-icon {
				animation: closeAnimation 0.1s ease-in-out;
			}
		}

		&__left-pane {
			flex: 1;
			display: flex;

			.enable-sticky {
				position: sticky;
				left: 0;
				z-index: 3;
				background-color: $white;
				flex: 0 0 40px;
			}
		}

		&__right-pane {
			width: 35px;
		}

		.count-indicator {
			text-align: left;
			position: relative;

			&--active {
				&:after {
					content: '';
					background-color: $dodger-blue;
					width: 5px;
					height: 5px;
					border-radius: 100%;
					position: absolute;
					right: 0;
					top: 43%;
				}
			}
		}

	}

	.expandable-content {
		display: block;
	}
}

@keyframes openAnimation {
	0% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes closeAnimation {
	0% {
		transform: rotate(-180deg);
	}

	100% {
		transform: rotate(0deg);
	}
}