@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.tab-container {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;

	.tab-line {
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		border-bottom: 1px solid $alto;

		.tab-icon {
			margin-right: 5px;
		}

		.tab {
			@include unselectable;
			@include font-medium-text-md;
			display: block;
			text-align: center;
			line-height: 14px;
			margin-right: 8px;
			padding: 8px 17px;
			cursor: pointer;
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;

			&.selected,
			&.selected:hover {
				box-shadow: 0px 2px 0px 0px $black inset, 0 1px 0 $white;
				border-left: 1px solid $alto;
				border-right: 1px solid $alto;
			}

			&.disabled,
			&.disabled:hover {
				pointer-events: none;
			}
		}

		&.primary .tab {
			background-color: $alto;

			&.selected,
			&.selected:hover {
				box-shadow: 0px 2px 0px 0px $dodger-blue inset, 0 1px 0 $white;
				background-color: $white;
			}

			&.disabled,
			&.disabled:hover {
				background-color: $gallery;
				color: $fuscous-gray;
			}

			&:hover {
				background-color: $alto;
			}
		}

		&.panel {
			.tab {
				background-color: inherit;

				&.selected,
				&.selected:hover {
					box-shadow: 0px 2px 0px 0px $black inset, 0 1px 0 $white;
					background-color: $white;
				}

				&.disabled,
				&.disabled:hover {
					background-color: inherit;
					color: $mine-shaft-transparent;
				}

				&:hover {
					background-color: $gallery;
				}
			}
		}

		&.secondary {
			border: none;

			.tab {
				background-color: $white;
				box-shadow: 0px 2px 0px 0px $silver inset;
				border: none;
				margin-right: 0;

				&.selected,
				&.selected:hover {
					box-shadow: 0px 2px 0px 0px $dodger-blue inset;
					border: none;
				}

				&.disabled,
				&.disabled:hover {
					background-color: $white;
					color: $fuscous-gray;
					box-shadow: 0px 2px 0px 0px $alto inset;
				}

				&:hover {
					background-color: $gallery;
				}
			}
		}
	}
}