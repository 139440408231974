@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.general-button {
	@include unselectable;
	@include font-regular-text-md;
	height: 32px;
	margin-bottom: 0px;
	border: none;
	box-shadow: none;
	border-radius: 4px;
	text-align: center;
	color: $white;
	margin: 0;
	cursor: pointer;
	padding: 0 16px;
	display: inline-block;
	width: fit-content;
	box-sizing: border-box;
	font-weight: 300;

	&.primary {
		background-color: $dodger-blue;
		color: $white;
		&:hover {
			background: $blue-ribbon;
		}
	}

	&.normal {
		background-color: $alto;
		color: $cod-gray;
		&:hover {
			background: $silver;
		}
	}

	&.discreet {
		background: $white;
		color: $cod-gray;
		border: 1px solid transparent;
		&:hover {
			border: 1px solid $cod-gray;
		}
	}

	&.disabled {
        // !important needed for overriding default iOS style
        background-color: $alto !important;
		border-color: $alto !important;
		pointer-events: none !important;
		color: $cod-gray !important;
        //

		svg {
			background: $alto;
			opacity: 0.3;

			> g {
				stroke: $mine-shaft-transparent;

				path {
					fill: $alto;
				}

				polyline {
					stroke: $mine-shaft-transparent;
				}
			}
		}

		.button-text {
			opacity: 0.3;
		}
	}

	svg {
		line-height: 32px;
		vertical-align: middle;
		height: 32px;
		margin-right: 8px;
		float: left;
	}

	.button-text {
		line-height: 32px;
		display: inline-block;
	}
}
/*.dropdown-toggle.disabled {
  color: $cod-gray !important;
}*/