@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.selection-info-message {
    @include unselectable;
    @include font-regular-text-md;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $wild-sand;
    color: $dove-gray;
    flex-direction: column;
    background-blend-mode: luminosity;

    span {
        max-width: 600px;
        text-align: center;
    }

    strong {
        font-weight: bold;
    }

    .separator {
        padding: 10px 0;
    }
}