$red: #ff000f;
$dodger-blue: #3366ff;
$blue-ribbon: #2a35ff;
$astral: #337ab7;
$elephant: #0e3849;
$matisse: #1d6092;
$blumine: #195c84;
$pattens-blue: #dcedfe;
$light-blue: #3366FF29;
$cod-gray: #1e1e1e;
$mine-shaft: #1f1f1f;
$dove-gray: #696969;
$gallery: #ebebeb;
$silver-chalice: #a0a0a0;
$nobel: #b3b3b3;
$white: #ffffff;
$gray: #7e7e7e;
$alto: #dbdbdb;
$athens-gray: #e9ecef;
$alabaster: #fafafa;
$alto-transparent: #dcdcdc6b;
$white-transparent: #ffffffed;
$mercury: #e6e6e6;
$mine-shaft-transparent: #1f1f1f4d;
$black-transparent: #0000001a;
$wild-sand: #f5f5f5;
$transparent: #00000000;
$ebb-transparent: #f0ececc7;
$dusty-gray: #999999;
$silver-transparent: #bababa73;
$iron: #dce0e0;
$concrete: #f3f3f3;
$silver: #bababa;
$westar: #e0dfda;
$fuscous-gray: #1f1f1f80;
$nero: #1f1f1f;
$thunder: #221e20;
$cinder: #020203;
$lavender-magenta: #ee82ee;
$malibu: #5ac8fa;
$viking: #5bd2df;
$amethyst: #cb2bd5;
$teak: #b48c64;
$tundora: #464646;
$classic-rose: #fac5fa;
$blue-bayoux: #475b77;
$spindle: #c5d2ef;
$black: #000000;
$jungle-green: #21A67A;
$jungle-green-transparent: #21A67A80;
$pomegranate: #EF3934;
$pomegranate-transparent: #EF393480;
$orange-peel: #FFA200;
$orange-peel-transparent: #FFA20080;
$chilean-fire: #fc7300;
$jacarta: #492970;
$citron: #8BBC21;
$red-berry: #910000;
$mariner: #2F7ED8;
$lochinvar: #2B908F;
$french-rose: #F15C80;
$submarine: #8b9898;
$school-bus-yellow: #FFD800;
$fantasy: #FBF0F0;
$orange-white: #FEF9EF;
$sandy-beach: #FFE9C2;
$sandy-beach-transparent: #FFE9C252;
$jagged-ice: #CAEADF;
$jagged-ice-transparent: #CAEADF52;
$cinderella: #FBCFCE;
$cinderella-transparent: #FBCFCE52;
$fruit-salad: #51A34B;
$smoke: #C8C8C8;

:export {
    lightblue:$light-blue;
    red: $red;
    dodgerBlue: $dodger-blue;
    blueRibbon: $blue-ribbon;
    astral: $astral;
    matisse: $matisse;
    elephant: $elephant;
    blumine: $blumine;
    spindle: $spindle;
    blueBayoux: $blue-bayoux;
    westar: $westar;
    pattensBlue: $pattens-blue;
    codGray: $cod-gray;
    mineShaft: $mine-shaft;
    doveGray: $dove-gray;
    gallery: $gallery;
    silverChalice: $silver-chalice;
    nobel: $nobel;
    white: $white;
    gray: $gray;
    silver: $silver;
    alto: $alto;
    athensGray: $athens-gray;
    alabaster: $alabaster;
    altoTransparent: $alto-transparent;
    whiteTransparent: $white-transparent;
    mercury: $mercury;
    mineShaftTransparent: $mine-shaft-transparent;
    blackTransparent: $black-transparent;
    wildSand: $wild-sand;
    concrete: $concrete;
    transparent: $transparent;
    ebbTransparent: $ebb-transparent;
    dustyGray: $dusty-gray;
    silverTransparent: $silver-transparent;
    iron: $iron;
    cinder: $cinder;
    thunder: $thunder;
    fuscousGray: $fuscous-gray;
    nero: $nero;
    tundora: $tundora;
    lavenderMagenta: $lavender-magenta;
    classicRose: $classic-rose;
    teak: $teak;
    amethyst: $amethyst;
    viking: $viking;
    malibu: $malibu;
    black: $black;
    jungleGreen: $jungle-green;
    pomegranate: $pomegranate;
    orangePeel: $orange-peel;
    chileanFire: $chilean-fire;
    jacarta: $jacarta;
    citron: $citron;
    redBerry: $red-berry;
    mariner: $mariner;
    lochinvar: $lochinvar;
    frenchRose: $french-rose;
    submarine: $submarine;
    schoolBusYellow: $school-bus-yellow;
    fantasy: $fantasy;
    orangeWhite: $orange-white;
    sandyBeach: $sandy-beach;
    sandyBeachTransparent: $sandy-beach-transparent;
    jaggedIce: $jagged-ice;
    jaggedIceTransparent: $jagged-ice-transparent;
    cinderella: $cinderella;
    cinderellaTransparent: $cinderella-transparent;
    fruitSalad: $fruit-salad;
    smoke: $smoke;
    jungleGreenTransparent: $jungle-green-transparent;
    pomegranateTransparent: $pomegranate-transparent;
    orangePeelTransparent: $orange-peel-transparent;
}
