@use '~theme/colors' as *;

.slider-container {
	margin: 0px 10px 0px 10px;
	width: 100%;

	.rc-slider {
		height: 30px;

		&.rc-slider-disabled {
			background-color: transparent;
		}

		.rc-slider-rail {
			height: 10px;
			border-radius: 0px;
		}

		.rc-slider-track {
			height: 0px;
		}

		.rc-slider-dot {
			display: none;
		}
	}

	.rc-slider-handle {
		width: 18px;
		height: 18px;
		margin-top: -4px;
		border: none;

		&.rc-slider-handle-2 {
			background-color: $white;
			border: 3px solid $pomegranate;
		}

		&.rc-slider-handle-1 {
			background-color: $white;
			border: 3px solid $orange-peel;
		}
	}
}

.multi-slider-container {
	position: relative;

	.pointer-style {
		position: relative;
		color: $fuscous-gray;

		.tooltip-style {
			min-width: 44px;
			position: absolute;
			top: -50px;
			transform: translateX(-50%);
			background: $white;
			padding: 5px;
			border-radius: 10px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			border: 1px solid $silver;
			font-size: 12px;
			font-weight: 700;
			text-align: center;
		}

		.pointer-down-arrow {
			width: 10px;
			height: 10px;
			background: #ffffff;
			border: 1px solid $silver;
			position: absolute;
			border-right: 0;
			border-bottom: 0;
			left: 50%;
			transform: translateX(-50%) rotate(225deg);
		}

	}

	.min-max {
		display: flex;
		justify-content: space-between;
	}
}