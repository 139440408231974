.horizontal-scroll-container {
    display: contents;

    .horizontal-scrollable-content {
        display: flex;
        align-items: center;
        max-width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            background: transparent;
            -webkit-appearance: none;
            width: 0;
            height: 0;
        }
    }

    .horizontal-arrow-prev,
    .horizontal-arrow-next {
        cursor: pointer;
        width: 20px;
    }

    .horizontal-arrow-hidden {
        cursor: default;
        visibility: hidden;
        width: 20px;
    }

}
