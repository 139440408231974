/**
 *
 * CheckboxComponent styles
 *
 */

@use "~theme/colors" as *;

.custom-checkbox,
.custom-checkbox-disabled {
	display: block;

	.checkbox-label {
		margin-right: 10px;
		cursor: default;
		display: inline-block;
	}

	.checkbox {
		border-radius: 10px;
	}

	path {
		stroke: $white;
	}
}

.custom-checkbox {
	cursor: pointer;

	svg > g > rect {
		&.indeterminate {
			fill: $dodger-blue;
		}
		&.fill-checked {
			fill: $dodger-blue;
			stroke: $dodger-blue;
		}

		&.fill-empty {
			fill: $white;
			stroke: $alto;
			background: none;
		}
		&.fill-white {
			stroke: $white;
		}
	}
}

.custom-checkbox-disabled {
	cursor: not-allowed;

	svg > g > rect {
		&.fill-empty {
			fill: $gallery;
			stroke: $alto;
		}

		&.fill-checked {
			fill: $alto;
			stroke: $alto;
		}

		&.indeterminate {
			fill: $alto;
			stroke: $gallery;
		}
	}
}
