@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;
@use "~theme/breakpoints" as *;

.date-cancel-button {
    margin-right: 10px;
    border: none;

    .button-text {
        @include font-regular-text-md;
        text-align: center;
    }
}

.calendar-interval-container {
    @include unselectable;
    @include font-regular-text-md;
    align-items: center;
    justify-content: flex-end;
    line-height: 16px;
    position: relative;
    display: inline-block;
    padding: 0;
    @include customScreenMaxWidth(1440) {
        position: initial;
        display: block;
    }

    .dateInterval {
        height: 35px;
        line-height: 35px;
        margin-right: 12px;

        .dateValue {
            color: $dodger-blue;
        }
    }
    $date-selector-container-width: 720px;
    $date-selector-container-narrow-width: 600px;
    .date-selector-from-left-corner {
        left: 0;
    }
    .date-selector-from-right-corner {
        right: 0;
    }
    .date-selector-container {
        position: absolute;
        top: auto;
        z-index: $date-selector-container-zIndex;
        background-color: $white;
        border-radius: 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        padding: 22px;
        width: $date-selector-container-width;
        height: 350px;

        &.narrow {
            width: $date-selector-container-narrow-width;
        }
        @include customScreenMaxWidth(1441) {
            position: fixed;
            top: calc(100vh/3);
            left: calc((100vw - $date-selector-container-width) / 2);
            right: auto;
        }

        .date-selector-top-container {
            height: 85%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .calendar-fixed-ranges {
                display: inline-block;
                text-align: left;

                .calendar-range-item {
                    padding: 0 0 12px;
                    font-size: 14px;

                    .item-text {
                        cursor: pointer;
                    }

                }
            }

            .rdp-caption {
                padding-bottom: 4px;
                text-align: start;
                .rdp-caption_label {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .rdp-nav {
                color: $submarine;
                position: absolute;
            }
            .rdp-caption_start {
                .rdp-nav {
                    left: 188%;
                    z-index: calc($date-selector-container-zIndex + 1);
                }
            }
            $rdp-tbody-height: 214px;
            .rdp {
                --rdp-cell-size: calc($rdp-tbody-height / 6);
                --rdp-outline: none;
            }

            .rdp-multiple_months {
                @include font-medium-text-md;
                line-height: 16px;
                margin: 0;
                padding-top: 10px;

                .rdp-month {
                    margin: 0px 12px;
                }
                .rdp-head_cell {
                    color: $submarine;
                    font-size: .875em;
                    font-weight: 500;
                }

                .rdp-table {
                    .rdp-tbody {
                        height: 0;
                        min-height: $rdp-tbody-height;
                        max-height: $rdp-tbody-height;
                    }
                }

                .rdp-day_selected {
                    background-color: $dodger-blue;
                    color: $white;
                }

                .rdp-day {
                    padding: 8px;
                    width: 100%;
                }

                .rdp-day:active {
                    color: $white;
                    border: 2px solid transparent;
                }

                .rdp-head_cell {
                    span {
                        text-transform: lowercase;
                        text-transform: capitalize;
                    }
                }

            }
        }

        .date-selector-bottom-container {
            .general-button {
                margin-left: 5px;
            }

            .footer-interval-text-container {
                float: left;
                font-size: 12px;
                cursor: default;
                padding: 10px 15px 0 15px;
                display: flex;
                min-width: 390px;
                width: 70%;

                .selected-days {
                    font-weight: bold;
                    margin-right: 15px;
                    min-width: 95px;
                }

                .footer-description {
                    text-align: left;
                }
            }
        }
    }
}

.range-text .calendar-interval-container .calendar-circle-wrapper {
    @include create-border($silver, 0px);
    cursor: pointer;
    display: flex;
    background: $white;
    height: 32px;
    min-width: 220px;
    align-items: center;
    justify-content: space-around;

    &.disabled {
        background: $gallery;
        color: $silver-chalice;
    }

    .date-text {
        font-size: 14px;
        line-height: 16px;
    }

    .date-selector-icon {
        margin-top: -4px;
        padding-right: 4px;
    }
}
