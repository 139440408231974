@use '~theme/colors' as *;
@use '~theme/typography' as *;

.loading {
	display: inline-block;
}

.loading-centered {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-text {
	font-size: $font-size-text-md;
	color: $dove-gray;
	font-style: italic;
}

.loading-sticky {
	position: sticky;
	top: 50%;
	bottom: 33%;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial {
	color: #1f1f1f;
	display: block;
	border-radius: 100%;
	position: relative;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
	> div {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
	> div.ABB_CommonUX_LoadingIndicator__isInternetExplorer {
	transform-origin: center;
	-webkit-animation: ABB_CommonUX_LoadingIndicator__rotation 3s infinite
		linear;
	animation: ABB_CommonUX_LoadingIndicator__rotation 3s infinite linear;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
	> div
	> svg {
	position: relative;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
	> div
	> svg
	> g {
	transform-origin: center;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
	> div
	> svg
	> g
	> circle {
	fill: transparent;
	transform-origin: center;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__xsmall {
	height: 16px;
	width: 16px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__xsmall
	> div
	> svg
	circle {
	stroke-width: 2px;
}
.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__small {
	height: 32px;
	width: 32px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__small
	> div
	> svg
	circle {
	stroke-width: 3px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__medium {
	font-size: 12px;
	height: 64px;
	width: 64px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__medium
	> div
	> svg
	circle {
	stroke-width: 4px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__large {
	font-size: 14px;
	height: 128px;
	width: 128px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial.ABB_CommonUX_LoadingIndicator__large
	> div
	> svg
	circle {
	stroke-width: 8px;
}

.ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial:not(.ABB_CommonUX_LoadingIndicator__determinate).ABB_CommonUX_LoadingIndicator__blue
	> div
	> svg
	> g
	> .ABB_CommonUX_LoadingIndicator__back {
	stroke: $dodger-blue;
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 80.4;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 75.1;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 75.1;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 80.4;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 75.1;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 75.1;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 75.1;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 80.4;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 75.1;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 75.1;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 80.4;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 75.1;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 75.1;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 80.4;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 75.1;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 75.1;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 80.4;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 75.1;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 75.1;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 80.4;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 75.1;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 75.1;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 80.4;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 75.1;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 75.1;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 80.4;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 75.1;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 75.1;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 80.4;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 75.1;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 75.1;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 80.4;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 75.1;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 75.1;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 80.4;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 75.1;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 75.1;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 80.4;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 75.1;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 75.1;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 80.4;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 75.1;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 75.1;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 80.4;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 75.1;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Small15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 75.1;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 80.4;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Small15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 80.4;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 75.1;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 80.4;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 80.4;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 40.2;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 42.9;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 42.9;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 40.2;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 42.9;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 42.9;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 42.9;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 40.2;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 42.9;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 42.9;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 40.2;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 42.9;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 42.9;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 40.2;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 42.9;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 42.9;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 40.2;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 42.9;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 42.9;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 40.2;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 42.9;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 42.9;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 40.2;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 42.9;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 42.9;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 40.2;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 42.9;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 42.9;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 40.2;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 42.9;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 42.9;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 40.2;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 42.9;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 42.9;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 40.2;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 42.9;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 42.9;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 40.2;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 42.9;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 42.9;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 40.2;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 42.9;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 42.9;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 40.2;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 42.9;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 42.9;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 40.2;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15XSmall15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 40.2;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 42.9;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 40.2;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 40.2;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 160.8;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 150.1;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 150.1;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 160.8;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 150.1;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 150.1;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 150.1;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 160.8;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 150.1;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 150.1;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 160.8;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 150.1;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 150.1;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 160.8;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 150.1;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 150.1;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 160.8;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 150.1;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 150.1;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 160.8;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 150.1;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 150.1;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 160.8;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 150.1;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 150.1;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 160.8;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 150.1;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 150.1;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 160.8;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 150.1;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 150.1;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 160.8;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 150.1;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 150.1;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 160.8;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 150.1;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 150.1;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 160.8;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 150.1;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 150.1;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 160.8;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 150.1;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 150.1;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 160.8;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 150.1;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Medium15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 150.1;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 160.8;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Medium15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 160.8;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 150.1;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 160.8;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 160.8;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 321.7;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 300.3;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 300.3;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large1 {
	0.0% {
		transform: rotate(0deg);
		stroke-dashoffset: 321.7;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 300.3;
	}

	3.3% {
		transform: rotate(0deg);
		stroke-dashoffset: 300.3;
	}

	33.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 300.3;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large2 {
	0.0% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	3.3% {
		transform: rotate(24deg);
		stroke-dashoffset: 321.7;
	}

	6.7% {
		transform: rotate(24deg);
		stroke-dashoffset: 300.3;
	}

	36.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 300.3;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large3 {
	0.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	6.7% {
		transform: rotate(48deg);
		stroke-dashoffset: 321.7;
	}

	10.0% {
		transform: rotate(48deg);
		stroke-dashoffset: 300.3;
	}

	40.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 300.3;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large4 {
	0.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	10.0% {
		transform: rotate(72deg);
		stroke-dashoffset: 321.7;
	}

	13.3% {
		transform: rotate(72deg);
		stroke-dashoffset: 300.3;
	}

	43.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 300.3;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large5 {
	0.0% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	13.3% {
		transform: rotate(96deg);
		stroke-dashoffset: 321.7;
	}

	16.7% {
		transform: rotate(96deg);
		stroke-dashoffset: 300.3;
	}

	46.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 300.3;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large6 {
	0.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	16.7% {
		transform: rotate(120deg);
		stroke-dashoffset: 321.7;
	}

	20.0% {
		transform: rotate(120deg);
		stroke-dashoffset: 300.3;
	}

	50.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 300.3;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large7 {
	0.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	20.0% {
		transform: rotate(144deg);
		stroke-dashoffset: 321.7;
	}

	23.3% {
		transform: rotate(144deg);
		stroke-dashoffset: 300.3;
	}

	53.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 300.3;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large8 {
	0.0% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	23.3% {
		transform: rotate(168deg);
		stroke-dashoffset: 321.7;
	}

	26.7% {
		transform: rotate(168deg);
		stroke-dashoffset: 300.3;
	}

	56.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 300.3;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large9 {
	0.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	26.7% {
		transform: rotate(192deg);
		stroke-dashoffset: 321.7;
	}

	30.0% {
		transform: rotate(192deg);
		stroke-dashoffset: 300.3;
	}

	60.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 300.3;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large10 {
	0.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	30.0% {
		transform: rotate(216deg);
		stroke-dashoffset: 321.7;
	}

	33.3% {
		transform: rotate(216deg);
		stroke-dashoffset: 300.3;
	}

	63.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 300.3;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large11 {
	0.0% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	33.3% {
		transform: rotate(240deg);
		stroke-dashoffset: 321.7;
	}

	36.7% {
		transform: rotate(240deg);
		stroke-dashoffset: 300.3;
	}

	66.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 300.3;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large12 {
	0.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	36.7% {
		transform: rotate(264deg);
		stroke-dashoffset: 321.7;
	}

	40.0% {
		transform: rotate(264deg);
		stroke-dashoffset: 300.3;
	}

	70.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}
}

@-webkit-keyframes ABB_CommonUX_LoadingIndicator__radial15Large13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 300.3;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large13 {
	0.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	40.0% {
		transform: rotate(288deg);
		stroke-dashoffset: 321.7;
	}

	43.3% {
		transform: rotate(288deg);
		stroke-dashoffset: 300.3;
	}

	73.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large14 {
	0.0% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}

	43.3% {
		transform: rotate(312deg);
		stroke-dashoffset: 321.7;
	}

	46.7% {
		transform: rotate(312deg);
		stroke-dashoffset: 300.3;
	}

	76.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 321.7;
	}
}

@keyframes ABB_CommonUX_LoadingIndicator__radial15Large15 {
	0.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 321.7;
	}

	46.7% {
		transform: rotate(336deg);
		stroke-dashoffset: 321.7;
	}

	50.0% {
		transform: rotate(336deg);
		stroke-dashoffset: 300.3;
	}

	80.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 321.7;
	}

	100.0% {
		transform: rotate(360deg);
		stroke-dashoffset: 321.7;
	}
}
