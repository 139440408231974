@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;
@use '~theme/table' as *;

.general-table {
	@include font-regular-text-md;
	line-height: 20px;

	.general-table-title {
		font-weight: normal;
		display: flex;
		justify-content: flex-start;
		cursor: pointer;

		svg {
			margin-left: 12px;
		}
	}

	.rc-table-placeholder {
		color: $mine-shaft-transparent;
		text-align: center;

		.rc-table-expanded-row-fixed {
			margin: auto;
			max-width: fit-content;
		}
	}

	.rc-table-row {
		cursor: pointer;

		&.selected .rc-table-cell {
			background-color: $wild-sand;
		}

		.rc-table-cell {
			word-break: break-word;
			background-color: $white;
		}
	}

	&.general-table-primary {
		&.rc-table-ping-left {
			.rc-table-cell-fix-left {
				@include fixed-column;
			}
		}

		.rc-table-content {
			overflow: auto !important;
			scrollbar-width: thin;
			scrollbar-color: $alto $wild-sand;

			table {
				border-collapse: separate;
				border-spacing: 0;
			}

			.rc-table-tbody {
				.rc-table-row {
					.rc-table-cell {
						padding: 8px 0px 8px 16px;
						font-size: 14px;
						line-height: 20px;
						border-bottom: 1px solid $silver;
					}
				}
			}
		}

		.rc-table-thead {
			th {
				font-weight: normal;
			}

			.rc-table-cell {
				@include font-medium-text-sm;
				font-weight: normal;
				line-height: 14.06px;
				color: $dove-gray;
				background-color: $white;
				text-transform: uppercase;
				border-bottom: 1px solid $black;
				padding: 0px 0px 8px 16px;
			}
		}
	}


	.rc-table-content {
		overflow: auto hidden !important;
		overscroll-behavior-x: none;
	}

	.rc-table-content::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: $wild-sand;
	}

	.rc-table-content::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px $black-transparent;
		-webkit-box-shadow: inset 0 0 6px $black-transparent;
		background-color: $alto;
	}
}

.general-table-pagination {
	margin-top: 24px;
}