@use '~theme/colors' as *;
@use '~theme/typography' as *;

.general-pagination {
	@include font-medium-text-md;
	width: 100%;
	height: 40px;
	line-height: 20px;
	text-align: center;

	.item-counter {
		justify-self: start;
	}

	.page-counter {
		justify-self: end;
	}

	.page-counter,
	.item-counter {
		.underlined {
			border-bottom: 2px solid $silver;
		}
	}

	.rc-pagination-prev,
	.rc-pagination-next {
		margin-bottom: 2px;
	}

	.rc-pagination-item {
		@include font-regular-text-md;
		border: none;
		border-bottom: 2px solid transparent;
		color: $black;
		transition: none;
		max-width: 16px;
		min-width: 16px;
		margin-right: 16px;
		margin-left: 8px;

		&:hover a,
		&:focus a {
			color: $black;
		}

		a {
			display: flex;
			justify-content: center;
		}

		&.rc-pagination-item-active {
			border-bottom: 2px solid $dodger-blue;
			color: $black;

			a {
				color: $black;
			}
		}
	}
}
