@use '~theme/typography' as *;
@use '~theme/colors' as *;
@use '~theme/zIndexes' as *;

@mixin unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin card-grid {
    display: grid;
    gap: 16px;
    grid-auto-flow: dense;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr) );
    align-content: start;
    margin-bottom: 16px;

    .overview-card__details{
        grid-column: 1 / -1;
    }
}

@mixin standard-tabContent-layout{
	height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 24px 16px 16px 0;
    gap: 24px;
    >:last-child {
        min-width: 0; //https://makandracards.com/makandra/66994-css-flex-and-min-width
    }
}

@mixin create-border(
    $color: $alto,
    $radius: 4px,
    $width: 1px,
    $style: solid
    ) {

    border-radius: $radius;
    border-width: $width;
    border-style: $style;
    border-color: $color;
}

@mixin shadow-inset-alto {
    box-shadow: inset 0px -1px 0px $alto;
}

@mixin standard-tab-container-spacing {
    padding-left: 24px;
    padding-right: 24px;
}

@mixin general-notification {
    .notification-bar {
		background: $silver-chalice;
		min-height: 48px;
		max-height: 156px;
		width: 320px;
		z-index: $notification-zIndex;
		overflow-y: hidden;
		margin-bottom: 16px;
		box-shadow: 0px 6px 12px rgb(0 0 0 / 35%);
		display: flex;
		padding: 13px;

		&.subscription-status-bar {
			background: $pomegranate;
		}
		&.error-bar {
			background: $pomegranate;
		}
		&.warning-bar {
			background: $orange-peel;
		}
		&.success-bar {
			background: $jungle-green;
		}
		&.info-bar {
			background: $blue-ribbon;
		}

		.link {
			color: $white;
			padding: 0 5px;
			text-decoration: underline;

			&:hover {
				color: $white;
				cursor: pointer;
			}
		}

		div {
			&:first-of-type {
				flex: 1;
				color: $white;
				line-height: 20px;
				text-align: left;
				font-size: 16px;
				display: flex;
				flex-direction: row;

				svg {
					margin-right: 12px;
				}

				span {
					flex: 1;
					width: 230px;
					align-self: center;
					max-height: 132px;
					overflow-y: auto;
					padding: 0px 2px;
					overflow-wrap: break-word;
					&.scrollbar-primary::-webkit-scrollbar {
						width: 4px;
						background-color: transparent;
					}

					&.scrollbar-primary::-webkit-scrollbar-thumb {
						border-radius: 2px;
						box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
						-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.16);
						background-color: rgba(0, 0, 0, 0.16);
					}
				}
			}

			&:last-of-type {
				height: 16px;
				flex: 0;
				margin-left: 13px;
				cursor: pointer;
				svg {
					vertical-align: unset;
				}

			}
		}
	}
}

@mixin only-ios-mobile {
	// only used in ios mobile
	// see: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout
	@supports (-webkit-touch-callout: none) {
		@content;
	}
}