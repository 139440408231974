/**
 *
 * RadioButton styles
 *
 */

@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

.radio-button,
.radio-button-disabled {
	@include unselectable;
	display: flex;
	position: relative;
	align-items: center;
	width: 100%;
	padding: 4px 0px;

	.radio-button-label {
		display: flex;
		align-items: center;
		width: calc(100% - 30px);

		.radio-button-label-icon {
			display: flex;
			align-items: center;
			margin-right: 2px;
		}

		.radio-button-label-text {
			@include unselectable;
			@include font-regular-text-md;
			display: block;
			float: left;
			margin-left: 7px;
			line-height: 30px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 300;
			max-width: calc(100% - 30px);

			&.large {
				line-height: 45px;
			}
		}
	}
}

.radio-button {
	cursor: pointer;

	svg {
		g {
			&.checked {
				stroke: $dodger-blue;
				fill: $dodger-blue;
				stroke-width: 1.42px;

				circle:nth-of-type(1) {
					fill: $dodger-blue;
					&:hover {
						fill: $blue-ribbon;
					}
				}

				circle:nth-of-type(2) {
					fill: $white;
				}
			}
		}

		g {
			&.empty {
				stroke: $nobel;
				fill: $mercury;
				stroke-width: 2.8px;

				circle:nth-of-type(1) {
					fill: $white;

					&:hover {
						fill: $mercury;
					}
				}
			}
		}
	}

    &.disabled {
        color: $nobel;

        &:hover {
            cursor: not-allowed;
        }

        svg {
            g {
                &.checked {
                    stroke: $alto;
                    stroke-width: 2.8px;

                    circle:nth-of-type(1) {
                        fill: $gallery;
                    }

                    circle:nth-of-type(2) {
                        fill: $cod-gray;
                        stroke-width: 1.42px;
                        opacity: 0.3;
                        stroke: $gallery;
                    }
                }
            }
        }
    }
}

