@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

.file-input-container {
	width: 100%;
	height: auto;
	background-color: $white;

	.input {
		margin-top: 12px;
		margin-bottom: 10px;

		.upload-input {
			display: none;
		}

		.file-input-label {
			width: auto;
			line-height: 30px;
		}
	}

	.selected-file {
		@include create-border($silver, 0px);
		min-width: 125px;
		min-height: 32px;
		margin-right: 6px;
		height: fit-content;
		line-height: 32px;
	}

	.selected-file-container {
		display: flex;
	}

	.file-container {
		padding: 0 5px;
		display: block;
	}

	div.selected-file[contentEditable]:empty::before {
		content: "No file selected";
		display: inline-block;
		color: $silver-chalice;
		padding-left: 5px;
	}

	.remove-button {
		&:hover {
			cursor: pointer;
		}
	}
}
