/**
 *
 * ContextMenuComponent styles
 *
 */

@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/zIndexes' as *;
@use '~theme/typography' as *;

.context-menu {
	position: relative;
	.context-menu-button,
	.context-menu-button-active {
		@include unselectable;
		cursor: pointer;
	}

	.context-menu-list {
        @include font-medium-text-md;
		position: absolute;
		top: 30px;
		right: 0px;
		width: 280px;
		z-index: $context-menu-list-zIndex;
		-webkit-box-shadow: 0px 6px 12px $black-transparent;
		box-shadow: 0px 6px 12px $black-transparent;

		.context-menu-list-item,
		.context-menu-list-item-disabled {
			@include unselectable;
			width: 100%;
			height: 38px;
			border-bottom: 1px solid $mercury;
			padding: 7px 10px;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			background: $white;
		}

		.context-menu-list-item {
			color: $cod-gray;
			cursor: pointer;
			&:hover {
				background: $alabaster;
			}
		}

		.context-menu-list-item-disabled {
			color: $silver;
		}
	}
}
