@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;

.general-select-container {
	.general-select__control {
		@include font-regular-text-md;
		border-radius: 0px;
		height: 32px;
		min-height: 32px;
		border-color: $silver;
		line-height: 16px;
		cursor: pointer;

		&.general-select__control--is-disabled {
			background-color: $gallery;
		}

		.general-select__value-container {
			padding: 2px 2px;
			margin-left: 8px;

			.general-select__placeholder {
				white-space: nowrap;
			}
		}

		.general-select__indicator {
			padding: 0px 8px;
		}
	}

	.general-select__menu {
		@include font-regular-text-md;
		border-radius: 0px;
		margin-top: 2px;
		line-height: 16px;
		z-index: $general-select__menu-zIndex;
		box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.25);

		.general-select__option {
			padding: 6px 8px 6px 12px;
			cursor: pointer;
			overflow-wrap: break-word;
		}

		.site-type-icon {
			margin-left: 4px;
			margin-right: 4px;
		}
	}
}

.general-select-wrapper {
	position: relative;

	&.has-error {
		.general-select__control {
			border-color: $pomegranate !important; // Ensure the border color is red when there's an error
			box-shadow: 0 0 0 1px $red !important; // Optionally, add a box-shadow for better visibility

			&:hover {
				border-color: $red !important; // Maintain red border on hover
			}
		}
	}
}

.error-indicator {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	pointer-events: none; // Prevent the icon from intercepting click events

	svg {
		width: 20px;
		height: 20px;
	}
}