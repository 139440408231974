@use "~theme/colors" as *;
@use "~theme/typography" as *;

.info-button-with-online-help {
    display: inline-block;

    .icon_info {
        margin-bottom: 5px;
        margin-left: 10px;
        cursor: pointer;
    }

    .info-button-with-online-help-tippy {
        @include font-regular-text-md;
        text-align: left;
        line-height: 16px;
        left: 4px;
        margin-left: 5px;

        #icon-link-path {
            fill: $dodger-blue;
        }
    }

    .description-link {
        padding-left: 5px;

        svg {
            margin: 0 10px 0 0 !important;
 }
    }
}