@use '~theme/colors' as *;
@use '~theme/typography' as *;

.time-input-container {
    display: flex;
    flex-direction: column;
    margin: 4px 20px 4px 0px;

    .flex {
        display: flex;
    }

    .label {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
    }

    .time-input {
        width: 175px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(209 213 219 / var(--tw-border-opacity));
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .error-icon {
        margin: 4px 0px 0px -16px;
        z-index: 99;
    }
    .error-message{
        color: red;
    }
}

.error {
    border-color: $pomegranate;
}

.time-input:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-color: $blue-ribbon;
}

.time-select {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-width: 1px;
    border-left-width: 0;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

time-select:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-color: $blue-ribbon;
}
