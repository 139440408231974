@use "~theme/colors" as *;
@use "~theme/typography" as *;
@use "~theme/mixins" as *;

.general-input-container {
	width: 100%;
	height: auto;
	background-color: $white;

	.input {
		display: flex;

		.general-input-label {
			width: 25%;
			font-size: 14px;
			line-height: 16px;
			padding-left: 25px;
			color: $dove-gray;
		}

		.input-search-condition-monitoring {
			@include font-regular-text-md;
			@include create-border($silver, 0px);
			height: 32px;
			line-height: normal;
			color: $mine-shaft;
			box-shadow: none;
			text-align-last: left;

			&:hover {
				background-color: $gallery;
			}

			&.invalid-value {
				border-bottom: 2px solid $pomegranate;
			}

			&:focus {
				@include create-border($dodger-blue, 0px);
			}

			&::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				font-style: normal;
			}

			&::-moz-placeholder {
				/* Firefox 19+ */
				font-style: normal;
			}

			&:-ms-input-placeholder {
				/* IE 10+ */
				font-style: normal;
				padding-left: 5px;
			}

			&:-moz-placeholder {
				/* Firefox 18- */
				font-style: normal;
			}
		}
	}

	input:disabled {
		background: $gallery;
	}

	.error-container {
		@include font-regular-text-sm;
		color: $pomegranate;
		padding: 5px 0px;
		line-height: 16px;
		text-align: left;

		svg {
			margin-top: -3px;
			margin-right: 6px;
		}
	}

	.description-container {
		@include font-regular-text-sm;
		color: $dove-gray;
		padding: 5px 0px;
		line-height: 16px;
	}
}
