@use '~theme/colors' as *;
@use '~theme/zIndexes' as *;

.fast-scroll-button {
	position: fixed;
	z-index: $button-zIndex;

	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0px 0px 15px $gray;
	&.icon-rotated {
		transform: rotateX(180deg);
	}
}
