@use "~theme/colors" as *;
@use '~theme/zIndexes' as *;

.loading-anchor {
    position: relative;
    height: 100%;
}

.loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: $loading-wrapper-zIndex;
    height: 100%;
    color: $silver;
    top: 0;
    left: 0;

    &.transparent {
        background-color: $white-transparent;
    }
}

.loading-modal {
    .modal-content {
        background-color: unset;
        .modal-body {
            padding: 0;
        }
        .full-screen {
            z-index: $general-dialog-zIndex;
            width: 100%;
            height: 100%;

            &.transparent {
                background-color: $white-transparent;
            }
        }
    }

    .fade &.modal-dialog{
        transform: unset;
        transition: unset;
    }
}

.fade.modal {
    z-index: $general-dialog-zIndex;
}

