@use '~theme/typography' as *;

$breadcrumb-link-text: rgba(105, 105, 105, 1);
$breadcrumb-active-text: rgba(31, 31, 31, 1);

.breadcrumb {
    margin-bottom: 0.6em !important;
    margin-left: 0.3em;
    align-items: center;
    &__link {
        color: $breadcrumb-link-text;
    }
    &__item {
        a {
            font-family: $font-family-regular;
            text-decoration: none;
            &:hover {
                color: $breadcrumb-active-text;
            }
        }
        &--active {
            a {
                color: $breadcrumb-active-text;
                font-family: $font-family-bold;
            }
        }
    }
    
    &__separator {
        margin: 0 8px;
    }
    &__active {
        color: $breadcrumb-active-text;
    }
}

.breadcrumb-item.active {
    color: $breadcrumb-active-text !important;
    font-family: $font-family-bold;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0 !important;
    content: none !important;
}
