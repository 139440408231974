@use '~theme/colors' as *;
@use '~theme/typography' as *;

.scrollable-list {
    position: relative;
    min-height: 14vh;
    height: 100%;
    border-top: 1px solid black;
    width: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;

    &_empty-label {
        @include font-regular-text-md;
        color: $mine-shaft-transparent;
        padding: 12px;
        border-bottom: 1px solid $alto;
    }

    .scrollable-list_empty-label {
        text-align: center;
    }

    &.loading {
        &::-webkit-scrollbar {
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* IE 10+ */
            background: transparent; /* Chrome/Safari/Webkit */
            width: 0px;
        }
        overflow-y: hidden;
    }

    .loading-wrapper {
        position: sticky;
    }
}
