/**
 *
 * SidePanelComponent styles
 *
 */

@use '~theme/colors' as *;
@use '~theme/mixins' as *;

.sidePanel-wrapper {
	@include create-border;
	background-color: $white;
	display: flex;
	min-width: 280px;
	max-width: 280px;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	padding-bottom: 24px;
	&.compact {
		height: fit-content;
	}
};
