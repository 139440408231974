@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use "~theme/typography" as *;
@use "~theme/zIndexes" as *;

.popout-chart-modal {
	&.modal {
		.modal-dialog {
			&.general-dialog {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				margin: 0;
				max-height: calc(var(--vh) * 100);

				.modal-content {
					position: relative;
					height: 100%;

					.zoom-button-container {
						display: flex;
						flex-direction: row-reverse;
						margin-top: 30px;
					}

					.popout-chart-container {
						display: flex;
						flex-direction: column;

						.transparent {
							background-color: white;
						}

						.popout-chart-row {
							&:first-child {
								padding-left: 25px;
							}
						}

						height: calc(100% - 62px);

						> .chart-style {
							height: 100%;
						}
					}
				}
			}
		}
	}
}

#popout-chart-row-button-container {
	margin: 10px 0 0 0;
}

.popout-chart-row {

	&.chart-wrapper-row {
		margin: 10px 0 0 0;
	}

	.col {
		height: 100%;
		padding: 0;

		.chart-container {
			height: 100%;
			position: relative;

			.chart-style {
				height: 100%;
			}
		}
	}

	&#popout-chart-row-button-container {
		min-height: 32px;
		padding-bottom: 0;

		.left-button-group {
			display: flex;
			align-items: center;
			column-gap: 10px;

			.unique-y-scale {
				display: flex;
				align-items: center;

				.unique-y-scale-switch {
					margin-right: 8px;
				}
			}

			.last-sync-date {
				@include unselectable;
				@include font-medium-text-md;

				span:first-of-type {
					margin-right: 5px;
				}
			}
		}

		.right-button-group {
			display: flex;
			text-align: right;
			align-items: center;
			column-gap: 10px;
			padding-right: 0px;

			.popout-button {
				cursor: pointer;
			}

			.download-all {
				cursor: pointer;
				background-color: $white;
				border: 1px solid $silver;
				padding: 5px 25px;
			}
		}
	}
}

.popout-chart-row--condition-insights {
	&#popout-chart-row-button-container {
		position: relative;
		top: 36px;
		z-index: 2;
		margin: 0;
		margin-right: 50px;
	}
}

.highcharts-tooltip-container {
	z-index: $highchart-tooltip-zIndex !important;
}

.highcharts-no-data {
	left: 0px !important;
	top: 0px !important;
	width: 100%;
	height: 210px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $wild-sand;

	@media (max-width: 1336px) {
		top: -26px !important;
	}

	> span {
		position: relative;
		font-weight: normal !important;
		font-size: 14px !important;
		line-height: 16px;
		font-family: "ABBvoice-Regular", sans-serif !important;
		color: $dove-gray !important;
		text-align: center;
		white-space: initial !important;
		display: block;
		left: initial !important;
		top: initial !important;

		@include only-ios-mobile {
			margin-left: 120px !important;
			margin-right: 120px !important;
			width: 430px;
		}

		max-width: 600px;
	}
}