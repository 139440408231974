/*
0000–1999: Elements and Components
2000–2999: Element and Component Drop Downs
3000–3999: Secondary Navigation
4000–4999: Header / Footer
5000–5999: Primary Navigation
6000–6999: Full Screen Features
7000–7999: Special Cases
8000–8999: Modals / Dialog Windows
9000–9999: Notifications
*/

//ELEMENTS AND COMPONENTS
//PowertrainAssestsOverview/CardComponent
$dropdown-icon-zIndex: 10;
//GeneralSelect
$general-select__menu-zIndex: 20;
//FastScrollButton
$button-zIndex: 100;
//WithLoadingStatus
$loading-wrapper-zIndex: 120;
//DateSelector
$date-selector-container-zIndex: 130;
//ContextMenu
$context-menu-list-zIndex: 140;
//GeneralFilter
$sidebar-zIndex: 1000;

//HEADER
//HeaderComponent
$pt-navbar-container-zIndex: 4000;

//MODALS
//GeneralDialog, LoadingWrapper
$general-dialog-zIndex: 8000;
//BulkUpload
$highchart-tooltip-zIndex: 8005;
$dialog-second-level-zIndex: 8010;
//Minimum resolution/width modal
$min-res-modal-zIndex: 8020;

//NOTIFICATIONS
//GeneralNotification, Portal
$notification-zIndex: 9000;
