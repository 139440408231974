@use '~theme/colors' as *;

.general-switch {
	.react-switch-handle {
		height: 20px;
		width: 20px;
		> div {
			display: flex;
			align-items: center;
		}
	}

	&.checked {
		.react-switch-bg {
			&:hover {
				background: $blue-ribbon !important;
			}
		}
	}

	&.empty {
		.react-switch-bg {
			&:hover {
				background: $silver-chalice !important;
			}
		}
	}

	.checked-vector-icon {
		path {
			fill: $dodger-blue;
		}
	}

	.unChecked-vector-icon {
		width: 100%;
		path {
			fill: $cod-gray;
		}
	}

	&.disabled {
		&.checked,
		&.empty,
		&.toggle {
			.react-switch-bg {
				background: $alto !important;
				&:hover {
					cursor: not-allowed !important;
				}
			}

			.checked-vector-icon {
				path {
					fill: $silver-chalice;
				}
			}

			.unChecked-vector-icon {
				width: 100%;

				path {
					fill: $silver-chalice;
				}
			}
		}
	}
}
